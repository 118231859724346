import React from "react"
import { graphql } from "gatsby"

import { useSpring, animated, config } from "react-spring"
import { motion } from "framer-motion"
import {
  Container,
  Layout,
  PageDivider,
  ProfileDesign,
  Section,
} from "../components"
import SEO from "../components/seo"
import OceanGateImage from "../images/ocean-gate"
import styled from "styled-components"

type TwoColumnsContainerBoxProps = {
  initial?: Object
  animate?: Object
  transition?: any
}
type TwoColumnsContainerContentProps = {
  width?: number
}

const AboutPage: React.FunctionComponent<{ data: any }> = props => {
  const { data } = props

  const fadeUp = useSpring({
    transform: "translateY(0)",
    opacity: 1,
    from: { transform: "translateY(30px)", opacity: 0 },
    config: config.molasses,
  })
  return (
    <Layout>
      <SEO title="About" />
      <animated.div style={fadeUp}>
        <Section>
          <Container>
            <TwoColumns>
              <Image>
                <ProfileDesign />
              </Image>
              <Content>
                <h5>
                  I’m a creator, developer, marketer, and tech aficionado.
                </h5>
                <blockquote>
                  "My opinions may have changed, but not the fact that I’m
                  right." <br />
                  &mdash; Noah Wong
                </blockquote>
                <p>
                  Marketing technologist with over 10 years of experience in the
                  medical device industry who specializes in articulating the
                  value of digital technologies and engagement strategies to
                  company leaders. Deep understanding of data and analytics
                  coupled with the creative mindset to transition raw data into
                  actionable insights and compelling marketing campaigns.
                  Well-versed in patient education through the development of
                  multiple awareness campaigns that include content marketing,
                  social media, search engine marketing, SEO, lead nurturing
                  campaigns and marketing automation (Marketo Certified Expert).
                </p>
              </Content>
            </TwoColumns>
          </Container>
        </Section>
        <SectionWrapper paddingTop="0" paddingBottom="0">
          <TwoColumnsContainer>
            <TwoColumnsContainerContent>
              <TwoColumnsContainerBox
                initial={{
                  opacity: 0,
                  x: "-100%",
                  filter: "blur(50px) drop-shadow(2px 2px 4px #000000)",
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  filter: "blur(0px) drop-shadow(2px 2px 4px #000000)",
                }}
                transition={{
                  duration: 0.8,
                  delay: 0.8,
                  type: "spring",
                  stiffness: 80,
                }}
              >
                <h2>Professional</h2>
                <p>
                  I am a marketing technologist with over 10 years of experience
                  in the medical device industry who specializes in articulating
                  the value of digital technologies and engagement strategies to
                  company leaders. Deep understanding of data and analytics
                  coupled with the creative mindset to transition raw data into
                  actionable insights and compelling marketing campaigns.
                  Well-versed in patient education through the development of
                  multiple awareness campaigns that include content marketing,
                  social media, search engine marketing, SEO, lead nurturing
                  campaigns and marketing automation (Marketo Certified Expert).
                </p>
                <p>
                  Equipped with a global mindset, Noah has spent a number of
                  years living and working outside the US and holds an MBA from
                  Thunderbird School of Global Management, #1 ranked school of
                  international business.
                </p>
                <p>Thanks for reading. &#128588;</p>
              </TwoColumnsContainerBox>
            </TwoColumnsContainerContent>
            <StyledOceanGateImage />
          </TwoColumnsContainer>
        </SectionWrapper>
        <PageDivider fluid={data.desktop.childImageSharp.fluid}>
          Hello
        </PageDivider>
      </animated.div>
    </Layout>
  )
}

// styled components

const TwoColumns = styled.div`
  @media (min-width: ${props => props.theme.responsive.md}) {
    display: flex;
    gap: 1rem;
  }
`
const Image = styled.div`
  @media (min-width: ${props => props.theme.responsive.md}) {
    flex: 1 1 0%;
  }
`
const Content = styled.div`
h5 {
  margin-top: 0;
}
@media (min-width: ${props => props.theme.responsive.md}) {
  flex: 2 1 0%;
}
  }
`
const SectionWrapper = styled(Section)`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    inset: 1em;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
  }
`
const TwoColumnsContainer = styled.div`
  background: var(--gray-800);
  color: white;
  position: relative;
  @media (min-width: ${props => props.theme.responsive.md}) {
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4em 0px;
  }
`
const TwoColumnsContainerContent = styled.div<TwoColumnsContainerContentProps>`
  max-width: ${props => (props.width ? props.width : props.theme.maxWidth.xl6)};
  margin: 0px auto;
  width: 100%;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 2.8rem;
  @media (min-width: ${props => props.theme.responsive.md}) {
    display: flex;
  }
`
const TwoColumnsContainerBox = styled(motion.div)<TwoColumnsContainerBoxProps>`
  text-align: left;
  padding: 0px;
  flex: 0 0 49%;
`

const StyledOceanGateImage = styled(OceanGateImage)`
  overflow: hidden;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  position: relative;
  @media (min-width: ${props => props.theme.responsive.md}) {
    position: absolute !important;
    width: 50%;
  }
`

export const pageQuery = graphql`
  query {
    desktop: file(relativePath: { eq: "trees.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default AboutPage
